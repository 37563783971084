import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  useTableData,
  Table,
} from 'react-ui-kit-exante';

import { defaultOrdering, defaultPageSize, pageSizes } from 'consts';
import {
  fetchPaidEvents,
  getColumnsForPaidEvents,
  PaidEvent,
} from 'services/CorporateActions';
import { FetchedColumn } from 'services/types';
import { calculateCountOfPages, getTableColumns } from 'utils';

const displayedColumns = [
  'account_id',
  'symbol',
  'name',
  'country_of_domicle',
  'ca_type',
  'ex_date',
  'pay_date',
  'position',
  'currency',
  'gross_amount',
  'sum',
  'tax_rate',
  'tax_sum',
  'status',
];

const Postings: FC = () => {
  const [actionColumns, setActionColumns] = useState<FetchedColumn[]>([]);

  const loadColumns = async () => {
    const response = await getColumnsForPaidEvents();
    setActionColumns(response);
  };

  useEffect(() => {
    loadColumns();
  }, []);

  const getEvents = useCallback(
    (props: IOnFetchArguments) => fetchPaidEvents(props),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getEvents },
      sorting: {
        getDefaultSorting: () => {
          return [{ id: 'id', desc: true }];
        },
      },
      saveViewParamsAfterLeave: true,
      pagination: {
        getDefaultPagination: () => ({ limit: defaultPageSize, skip: 0 }),
      },
    }),
    [getEvents],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<{
    events: PaidEvent[];
    pagination: { total: number };
  }>(tableData);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination?.total || 0, limit),
    [limit, data],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const columns = useMemo(
    () =>
      getTableColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        fetchedColumns: actionColumns,
      }),
    [setFilter, removeFilter, actionColumns],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      title="Postings"
      tableId="paidEvents"
      displayedColumnKeys={displayedColumns}
      showTableInfo
      isFlexLayout
      showScrollbar
      data={data?.events ?? []}
      columns={columns}
      isLoading={isLoading}
      isPinnedHeader
      saveColumnOrder
      hasFilters
      filteringProps={filteringProps}
      manualSortBy
      onSort={handleSorting}
      defaultSortBy={defaultOrdering}
      hasPagination
      pageSizes={pageSizes}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total: data?.pagination.total || 0,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};

export default Postings;
