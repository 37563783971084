import {
  TCommonParams,
  Notification,
  IOnFetchArguments,
} from 'react-ui-kit-exante';

import { getPayloadWithFormattedDates, getSortingParams } from 'utils';

import { corpActionsInstanceApi, corpActionsInstanceApiV1 } from '../../api';
import { FetchedColumn } from '../../types';

import {
  CorporateActionInputForm,
  UpdateEventPayload,
  ExpandedCorpAction,
  ICorpAction,
} from './types';

const mapEvent = ({ ca_extend, ...item }: ICorpAction) => ({
  ...ca_extend,
  ...item,
  extendedUId: ca_extend?.uid,
  extendedId: ca_extend?.id,
});

export async function getCorporateActionsExport(params: TCommonParams) {
  const { sorting, skip, page, ...payload } = params;
  try {
    const { data } = await corpActionsInstanceApiV1.get({
      endPoint: `/events?`,
      params: {
        ...getPayloadWithFormattedDates(payload, ['deadline']),
        with_transactions: true,
      },
    });
    const actions: ExpandedCorpAction[] = (data.content as ICorpAction[]).map(
      mapEvent,
    );

    return actions;
  } catch (error) {
    Notification.error({
      title: 'Get corporate actions error',
      description: String(error),
    });
  }
  return null;
}

export async function addNewCorporateAction(payload: CorporateActionInputForm) {
  return corpActionsInstanceApi.post({
    endPoint: `/events`,
    data: payload,
  });
}

export async function updateEvent(payload: UpdateEventPayload) {
  return corpActionsInstanceApi.put({
    endPoint: `/events?uuid=${payload.uid}`,
    data: payload,
  });
}

export async function deleteEvents(ids: number[]) {
  try {
    await corpActionsInstanceApi.delete({
      endPoint: `/events`,
      data: ids.map((id) => ({ id })),
    });
    Notification.success({ title: 'Events was deleted' });
  } catch (error) {
    Notification.error({ title: 'Delete events error' });
  }
  return null;
}

export async function getCorporateActions(params: TCommonParams) {
  const { sorting, skip, page, ...payload } = params;
  try {
    const { data } = await corpActionsInstanceApiV1.get({
      endPoint: `/events?`,
      params: {
        ...getPayloadWithFormattedDates(payload, ['deadline']),
        ...getSortingParams(sorting),
        offset: page,
        with_transactions: true,
      },
    });
    const actions: ExpandedCorpAction[] = (data.content as ICorpAction[]).map(
      mapEvent,
    );
    return {
      actions,
      pagination: data.pagination,
    };
  } catch (error: any) {
    Notification.error({
      title: 'Load actions error',
      description: String(error),
    });
    return {
      actions: [],
      pagination: { total: 0 },
    };
  }
}

export async function getColumnsForCorporateActions() {
  try {
    const response = await corpActionsInstanceApi.get({
      endPoint: `/events/fields`,
    });
    return response.data.content as FetchedColumn[];
  } catch (error) {
    Notification.error({
      title: 'Load columns for actions table error',
      description: String(error),
    });
    return [];
  }
}

export async function fetchPaidEvents({ params }: IOnFetchArguments) {
  const { sorting, skip, page, ...payload } = params;
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/events/paid`,
      params: {
        ...getPayloadWithFormattedDates(payload, ['deadline']),
        ...getSortingParams(sorting),
        offset: page,
      },
    });
    return {
      events: result.data.content,
      pagination: result.data.pagination,
    };
  } catch (error) {
    Notification.error({
      title: 'Get paid events error',
      description: String(error),
    });
  }
  return {
    events: [],
    pagination: { total: 0 },
  };
}

export async function getColumnsForPaidEvents() {
  try {
    const response = await corpActionsInstanceApi.get({
      endPoint: `/events/paid/fields`,
    });
    return response.data.content as FetchedColumn[];
  } catch (error) {
    Notification.error({
      title: 'Load columns error',
      description: String(error),
    });
    return [];
  }
}

export async function fetchActionHistory({ params }: IOnFetchArguments) {
  const { sorting, skip, page, ...payload } = params;
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: '/events/history?',
      params: {
        ...getPayloadWithFormattedDates(payload),
        ...getSortingParams(sorting),
        offset: page,
      },
    });
    return {
      events: result.data.content,
      pagination: result.data.pagination,
    };
  } catch (error) {
    Notification.error({
      title: 'Get corporate action history error',
      description: String(error),
    });
  }
  return {
    events: [],
    pagination: { total: 0 },
  };
}
