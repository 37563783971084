import { useState, SyntheticEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Panel, Tab, Tabs, TabPanel, Notification } from 'react-ui-kit-exante';

import { Params } from 'routes/types';
import { getCorporateActionDetails } from 'services/CorporateActions';

import { Clients } from './Clients/Clients';
import styles from './CorporateActionsDetails.module.css';
import { DetailedInformation } from './DetailedInformation/DetailedInformation';
import { CorporateActionDetail } from './DetailedInformation/types';
import { History } from './History/History';
import { VoteResults } from './VoteResults/VoteResults';

export const CorporateActionDetails = () => {
  const params = useParams<Params>();
  const actionId = Number(params?.actionId);
  const [selectedTab, setSelectedTab] = useState(0);

  const [details, setDetails] = useState<CorporateActionDetail>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getCorporateActionDetails(actionId);
      setDetails(response);
    } catch (error) {
      Notification.error({ title: 'Load detail error' });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [actionId]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Panel title={`Event ID: ${actionId}`}>
      <div className={styles.Header}>
        <Tabs
          className={styles.Tabs}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab label="Details" />
          <Tab label="Clients" />
          <Tab label="Vote results" />
          <Tab label="History" />
        </Tabs>
      </div>
      <div className={styles.TabBlocks}>
        <TabPanel value={selectedTab} index={0}>
          <DetailedInformation details={details} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Clients uid={details?.uid as string} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <VoteResults id={actionId} />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <History uid={details?.uid as string} />
        </TabPanel>
      </div>
    </Panel>
  );
};
