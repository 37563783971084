export enum FieldType {
  string = 'string',
  bool = 'bool',
}

export interface DetailItem {
  value: string;
  name: string;
  parentName: string;
  fieldType: FieldType;
  readonly: boolean;
}

export interface CorporateActionDetail {
  [key: string]: string | boolean | Record<string, string>;
}
